import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('div',[_c('div',{ref:"header"},[_vm._t("header",function(){return [_c('div',{staticClass:"mx-8 py-4 d-flex justify-space-between align-center"},[_vm._t("header-title",function(){return [_c('span',{staticClass:"text-h6"},[_vm._t("header-title-text",null,{"currentTab":_vm.routeParam})],2)]},{"currentTab":_vm.routeParam}),_vm._t("header-button",null,{"currentTab":_vm.routeParam})],2),_c(VDivider)]},{"currentTab":_vm.routeParam})],2),_vm._t("content",function(){return [_c('div',{staticClass:"d-flex product_scrollbar"},[(_vm.items.length)?[_c('scrollable',{staticClass:"col-2 ma-0 pa-0",attrs:{"hide-top":"","hide-bottom":"","height":(_vm.availableHeight + "px")},scopedSlots:_vm._u([{key:"scrollable",fn:function(){return [_vm._t("content-tabs",function(){return [(_vm.items.length)?_c('div',{staticClass:"d-flex flex-column"},[_vm._l((_vm.items),function(tabItem,index){
var _obj;
return [(!tabItem.component)?_vm._t(("tab-" + (tabItem.code))):_c(VCard,{key:("item-" + index),staticClass:"d-flex",attrs:{"to":{ params: ( _obj = {}, _obj[_vm.routeParamName] = tabItem.code, _obj ) },"flat":"","color":"transparent"}},[_c(VSheet,{key:("sheet-" + index),attrs:{"color":tabItem.code === _vm.routeParam ? 'primary' : '',"width":8}}),_vm._t(("tab-" + (tabItem.code)),function(){return [_c('div',{staticClass:"my-4 ms-4"},[_vm._t(("tab-label-" + (tabItem.code)),function(){return [_c('span',[_c('b',[_vm._v(_vm._s(tabItem.name))])])]},{"item":tabItem}),_vm._t(("tab-content-" + (tabItem.code)))],2)]})],2)]})],2):_vm._e()]})]},proxy:true}],null,true)}),_c(VDivider,{staticClass:"mx-0",attrs:{"vertical":""}})]:_vm._e(),_c('scrollable',{staticClass:"ma-0 pa-0",class:_vm.items.length ? 'col-10' : 'col-12',attrs:{"height":(_vm.availableHeight + "px")},scopedSlots:_vm._u([{key:"scrollable",fn:function(){return [_c(VWindow,{attrs:{"value":_vm.currentIndex}},[(!_vm.items.length)?_vm._t("scrollable.content",null,{"height":_vm.availableHeight}):[_vm._t("content-top"),_vm._l((_vm.items),function(tabItem,index){return _c(VWindowItem,{key:("content-" + index),attrs:{"eager":_vm.eager}},[_vm._t(("content-" + (tabItem.code)),null,{"content":tabItem,"currentTab":_vm.$route.params[_vm.routeParamName]})],2)})]],2)]},proxy:true}],null,true)})],2),_vm._t("default")]},{"availableHeight":_vm.availableHeight})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }