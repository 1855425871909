export enum HTTPResponseCode {
  OK = 200,
  ACCEPTED = 201,
  CREATED = 202,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  NOT_ALLOWED = 405,
  UNPROCESSABLE_CONTENT = 422,
  SERVER_ERROR = 500
}
